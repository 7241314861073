/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'MontserratAlternates';
    font-weight: 100;
    src: local('Lato'), url(./fonts/MontserratAlternates-ExtraLight.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'MontserratAlternates';
    font-weight: 200;
    src: local('Lato'), url(./fonts/MontserratAlternates-Thin.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'MontserratAlternates';
    font-weight: 300;
    src: local('Lato'), url(./fonts/MontserratAlternates-Light.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'MontserratAlternates';
    font-weight: 400;
    src: local('Lato'), url(./fonts/MontserratAlternates-Regular.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'MontserratAlternates';
    font-weight: 500;
    src: local('Lato'), url(./fonts/MontserratAlternates-Medium.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'MontserratAlternates';
    font-weight: 600;
    src: local('Lato'), url(./fonts/MontserratAlternates-SemiBold.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'MontserratAlternates';
    font-weight: 700;
    src: local('Lato'), url(./fonts/MontserratAlternates-Bold.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'MontserratAlternates';
    font-weight: 800;
    src: local('Lato'), url(./fonts/MontserratAlternates-ExtraBold.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'MontserratAlternates';
    font-weight: 900;
    src: local('Lato'), url(./fonts/MontserratAlternates-Black.ttf) format('truetype');
  }
  